function Footer() {
	type TFooterLink = {
		text: string
		link: string
	}

	const footerLinks: TFooterLink[] = [
		{
			text: "Datenschutz",
			link: process.env.PUBLIC_URL + "/",
		},
		{
			text: "Teilnahmebedingungen",
			link: process.env.PUBLIC_URL + "/",
		},
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum",
		},
		{
			text: "Kontakt",
			link: process.env.PUBLIC_URL + "/",
		},
	]

	return (
		<footer>
			<section className="bg-footerBlue border-white  border-b-[6px] px-4">
				<div className="grid grid-cols-[240px_1fr] max-w-3xl mx-auto">
					<ul className=" py-8">
						{footerLinks.map((footerLink, i) => (
							<li key={i} className="">
								<a href={footerLink.link} className="text-[20px] leading-loose font-VAG text-white" target={"_blank"}>
									{footerLink.text}
								</a>
							</li>
						))}
					</ul>
					<div className=" text-right relative">
						<img
							src={process.env.PUBLIC_URL + "/images/bear.png"}
							alt="Bärchen"
							width="472"
							height="756"
							className="max-w-[183px] absolute -bottom-2 md:max-w-[235px] right-0"
						/>
					</div>
				</div>
			</section>
			<section className="bg-footerBlueDark text-white text-[27px] leading-none text-center font-VAG py-8 px-4 mb-28 md:mb-0">
				Haribo macht Kinder froh und Erwachsene ebenso!
			</section>
		</footer>
	)
}

export default Footer
