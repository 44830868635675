const Header = () => {
	return (
		<header className={""}>
			<div className="container max-w-[970px] mx-auto px-4 py-6 ">
				<a href="/" className="text-center">
					<img
						src={process.env.PUBLIC_URL + "/images/haribo.jpg"}
						alt="Haribo"
						width="514"
						height="130"
						className="max-w-[108px] md:max-w-[235px] h-auto mx-auto md:ml-0"
					/>
				</a>
			</div>
		</header>
	)
}

export default Header
